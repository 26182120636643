var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.size === '1')?_c('h1',{class:['heading heading-01', {'heading--align-center' : _vm.align === 'center'}, 
																									{'heading--transform-uppercase' : _vm.uppercase}, 
																									{'heading--margin-none' : _vm.margin === 'none'}, 
																									{'heading--color-primary' : _vm.color === 'primary'}, 
																									{'heading--color-secondary' : _vm.color === 'secondary'}, 
																									{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																									{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):(_vm.size === '2')?_c('h2',{class:['heading heading-02', {'heading--align-center' : _vm.align === 'center'}, 
																											{'heading--transform-uppercase' : _vm.uppercase}, 
																											{'heading--margin-none' : _vm.margin === 'none'}, 
																											{'heading--color-primary' : _vm.color === 'primary'}, 
																											{'heading--color-secondary' : _vm.color === 'secondary'}, 
																											{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																											{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):(_vm.size === '3')?_c('h3',{class:['heading heading-03', {'heading--align-center' : _vm.align === 'center'}, 
																											{'heading--transform-uppercase' : _vm.uppercase}, 
																											{'heading--margin-none' : _vm.margin === 'none'}, 
																											{'heading--color-primary' : _vm.color === 'primary'}, 
																											{'heading--color-secondary' : _vm.color === 'secondary'}, 
																											{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																											{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):(_vm.size === '4')?_c('h4',{class:['heading heading-04', {'heading--align-center' : _vm.align === 'center'}, 
																											{'heading--transform-uppercase' : _vm.uppercase}, 
																											{'heading--margin-none' : _vm.margin === 'none'}, 
																											{'heading--color-primary' : _vm.color === 'primary'}, 
																											{'heading--color-secondary' : _vm.color === 'secondary'}, 
																											{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																											{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):(_vm.size === '5')?_c('h5',{class:['heading heading-05', {'heading--align-center' : _vm.align === 'center'}, 
																											{'heading--transform-uppercase' : _vm.uppercase}, 
																											{'heading--margin-none' : _vm.margin === 'none'}, 
																											{'heading--color-primary' : _vm.color === 'primary'}, 
																											{'heading--color-secondary' : _vm.color === 'secondary'}, 
																											{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																											{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):(_vm.size === '6')?_c('h6',{class:['heading heading-06', {'heading--align-center' : _vm.align === 'center'}, 
																											{'heading--transform-uppercase' : _vm.uppercase}, 
																											{'heading--margin-none' : _vm.margin === 'none'}, 
																											{'heading--color-primary' : _vm.color === 'primary'}, 
																											{'heading--color-secondary' : _vm.color === 'secondary'}, 
																											{'heading--color-tertiary' : _vm.color === 'tertiary'}, 
																											{'heading--whiteout' : _vm.whiteout}]},[_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }