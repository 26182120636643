<template>
  <h1 v-if="size === '1'" :class="['heading heading-01', {'heading--align-center' : align === 'center'}, 
																									{'heading--transform-uppercase' : uppercase}, 
																									{'heading--margin-none' : margin === 'none'}, 
																									{'heading--color-primary' : color === 'primary'}, 
																									{'heading--color-secondary' : color === 'secondary'}, 
																									{'heading--color-tertiary' : color === 'tertiary'}, 
																									{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h1>
  <h2 v-else-if="size === '2'" :class="['heading heading-02', {'heading--align-center' : align === 'center'}, 
																											{'heading--transform-uppercase' : uppercase}, 
																											{'heading--margin-none' : margin === 'none'}, 
																											{'heading--color-primary' : color === 'primary'}, 
																											{'heading--color-secondary' : color === 'secondary'}, 
																											{'heading--color-tertiary' : color === 'tertiary'}, 
																											{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h2>
  <h3 v-else-if="size === '3'" :class="['heading heading-03', {'heading--align-center' : align === 'center'}, 
																											{'heading--transform-uppercase' : uppercase}, 
																											{'heading--margin-none' : margin === 'none'}, 
																											{'heading--color-primary' : color === 'primary'}, 
																											{'heading--color-secondary' : color === 'secondary'}, 
																											{'heading--color-tertiary' : color === 'tertiary'}, 
																											{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h3>
  <h4 v-else-if="size === '4'" :class="['heading heading-04', {'heading--align-center' : align === 'center'}, 
																											{'heading--transform-uppercase' : uppercase}, 
																											{'heading--margin-none' : margin === 'none'}, 
																											{'heading--color-primary' : color === 'primary'}, 
																											{'heading--color-secondary' : color === 'secondary'}, 
																											{'heading--color-tertiary' : color === 'tertiary'}, 
																											{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h4>
  <h5 v-else-if="size === '5'" :class="['heading heading-05', {'heading--align-center' : align === 'center'}, 
																											{'heading--transform-uppercase' : uppercase}, 
																											{'heading--margin-none' : margin === 'none'}, 
																											{'heading--color-primary' : color === 'primary'}, 
																											{'heading--color-secondary' : color === 'secondary'}, 
																											{'heading--color-tertiary' : color === 'tertiary'}, 
																											{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h5>
  <h6 v-else-if="size === '6'" :class="['heading heading-06', {'heading--align-center' : align === 'center'}, 
																											{'heading--transform-uppercase' : uppercase}, 
																											{'heading--margin-none' : margin === 'none'}, 
																											{'heading--color-primary' : color === 'primary'}, 
																											{'heading--color-secondary' : color === 'secondary'}, 
																											{'heading--color-tertiary' : color === 'tertiary'}, 
																											{'heading--whiteout' : whiteout}]">
		<slot></slot>
	</h6>
</template>

<script>
export default {
	props: {
		size: String,
		align: String,
		uppercase: Boolean,
		whiteout: Boolean,
		color: String,
		margin: String,
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.heading-01{
	font-family: var(--heading-01-font-family);
	color: var(--heading-01-color);
	font-size: var(--heading-01-mobile-font-size);
	line-height: var(--heading-01-mobile-line-height);
	font-weight: var(--heading-01-font-weight);
	margin: var(--heading-01-margin);
	letter-spacing: var(--heading-01-letter-spacing);
	@include breakpoint(sm){
		font-size: var(--heading-01-font-size);
		line-height: var(--heading-01-line-height);
	}
}
.heading-02{
	font-family: var(--heading-02-font-family);
	color: var(--heading-02-color);
	font-size: var(--heading-02-mobile-font-size);
	line-height: var(--heading-02-mobile-line-height);
	font-weight: var(--heading-02-font-weight);
	margin: var(--heading-02-margin);
	letter-spacing: var(--heading-02-letter-spacing);
	border-top: var(--heading-02-border-top);
	border-bottom: var(--heading-02-border-bottom);
	@include breakpoint(sm){
		font-size: var(--heading-02-font-size);
		line-height: var(--heading-02-line-height);
	}
}
.heading-03{
	font-family: var(--heading-03-font-family);
	color: var(--heading-03-color);
	font-size: var(--heading-03-font-size);
	line-height: var(--heading-03-line-height);
	font-weight: var(--heading-03-font-weight);
	margin: var(--heading-03-mobile-margin, var(--heading-03-margin, 100px));
	letter-spacing: var(--heading-03-letter-spacing);
	@include breakpoint(sm){
		margin: var(--heading-03-margin);
	}
}
.heading-04{
	font-family: var(--heading-04-font-family);
	color: var(--heading-04-color);
	font-size: var(--heading-04-font-size);
	line-height: var(--heading-04-line-height);
	font-weight: var(--heading-04-font-weight);
	margin: var(--heading-04-margin);
	letter-spacing: var(--heading-04-letter-spacing);
}
.heading-05{
	font-family: var(--heading-05-font-family);
	color: var(--heading-05-color);
	font-size: var(--heading-05-font-size);
	line-height: var(--heading-05-line-height);
	font-weight: var(--heading-05-font-weight);
	margin: var(--heading-05-margin);
	letter-spacing: var(--heading-05-letter-spacing);
}
.heading-06{
	font-family: var(--heading-06-font-family);
	color: var(--heading-06-color);
	font-size: var(--heading-06-font-size);
	line-height: var(--heading-06-line-height);
	font-weight: var(--heading-06-font-weight);
	margin: var(--heading-06-margin);
	letter-spacing: var(--heading-06-letter-spacing);
}
.heading--margin-none{
	margin-bottom: 0!important;
}
.heading--align-center{
	text-align: center;
}
.heading--transform-uppercase{
	text-transform: uppercase;
}

.heading--decoration-left, .heading--decoration-left-right{
	position: relative;
	display: inline-block;

	&::before{
		position: absolute;
    content: "";
    width: var(--heading-decoration-width);
    height: 0;
    top: 50%;
    right: 100%;
    margin: var(--heading-decoration-margin);
    border-top: var(--heading-decoration-border);
	}
}
.heading--decoration-right, .heading--decoration-left-right{
	position: relative;
	display: inline-block;

	&::after{
		position: absolute;
    content: "";
    width: var(--heading-decoration-width);
    height: 0;
    top: 50%;
    left: 100%;
    margin: var(--heading-decoration-margin);
    border-top: var(--heading-decoration-border);
	}
}
.heading--color-primary{
	color: var(--heading-color-primary);
}
.heading--color-secondary{
	color: var(--heading-color-secondary);
}
.heading--color-tertiary{
	color: var(--heading-color-tertiary);
}
.heading--whiteout{
	color: #fff;
}

.card--link:hover{
	.heading-05{
		text-decoration: var(--card-link-heading-text-decoration);
	}
}
</style>

<!-- Used in : AR, DS -->